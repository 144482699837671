<template>
  <div class="supplierPendingDetailPage">
    <div class="facilityBox">
      <el-tabs v-model="currentStep" @tab-click="stepChange">
        <!-- 服务商 -->
        <el-tab-pane label="付款申请" name="5"></el-tab-pane>
        <el-tab-pane label="上传发票" name="6"></el-tab-pane>
      </el-tabs>
      <Payment v-if="currentStep == 5" :enterpriseFullName="enterpriseFullName" @uploadVoucher="uploadVoucher">
      </Payment>
      <Check v-if="currentStep == 6" :enterpriseFullName="enterpriseFullName" @uploadVoucher="uploadVoucher"></Check>

      <el-dialog width="1200px" title="线下付款凭证" center :visible.sync="dialogVisible">
        <UploadFile ref="uploadFile" @uploadVoucher="uploadVoucher"></UploadFile>
      </el-dialog>


    </div>
  </div>
</template>

<script>
import Payment from './cmpt/payment'
import Check from './cmpt/check'

import UploadFile from './uploadFile/uploadFile'

export default {
  data() {
    return {
      currentStep: '5', //当前处于第几步
      enterpriseFullName: '', //企业名称
      dialogVisible: false
    }
  },
  methods: {
    stepChange(e) {
      this.currentStep = String(e.name)
    },
    // 打开 或者 关闭 凭证dialog
    uploadVoucher(e) {
      this.dialogVisible = e

      this.$nextTick(function () {
        this.$refs.uploadFile.getUploadFile()
      })

    }
  },
  created() {
    this.enterpriseFullName = this.$route.query.enterpriseFullName
    this.currentStep = this.$route.query.currentStep ? this.$route.query.currentStep : '5'
  },
  components: {
    Payment,
    Check,
    UploadFile
  }
}
</script>

<style lang="scss" scoped>

</style>