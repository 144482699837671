import { render, staticRenderFns } from "./pendingDetail.vue?vue&type=template&id=6312b600&scoped=true"
import script from "./pendingDetail.vue?vue&type=script&lang=js"
export * from "./pendingDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6312b600",
  null
  
)

export default component.exports